.input-with-link {
  position: relative;
}

.input-with-link .input-link-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

/* Style the input to look like a link */
.link-like-input {
  color: blue; /* Link color */
  text-decoration: underline; /* Underline to mimic a hyperlink */
  cursor: pointer; /* Pointer cursor to indicate interactivity */
  background-color: transparent; /* Remove background to look less like an input */
}
