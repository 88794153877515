:root {
  --bs-primary: #67816C !important;
  --bs-secondary: #7A8B80 !important;

}

.btn-primary {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:disabled {
  background-color: var(--bs-secondary) !important;
  border-color: var(--bs-secondary) !important;
}


.content {
  padding-top: 60px;
}

.checkmark {
  float: right;
  font-size: 0.8em;
  line-height: 1.5;
}

.no-border {
  border: none !important;
  box-shadow: none !important;
}

.logo {
  font-size: 90px;
  animation: fade-in-out 4s infinite;
  transform: rotate(90deg);
  color: var(--bs-primary)
}

@keyframes fade-in-out {
  0%, 100% { opacity: 0.1; }
  50% { opacity: 0.9; }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 500px;
}

.loading-icon {
  font-size: 90px;
  color: var(--bs-primary);
  animation: fade-in 5s forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/* Table things */
.table-responsive {
  overflow-x: auto;
}

/* Hide columns on smaller screens */
@media (max-width: 768px) {
  
  .col-productType,
  .col-productGrouping {
    display: none;
  }
}

@media (max-width: 576px) {
  .col-price,
  .col-uom {
    display: none;
  }
}



/* Custom styles for the off-canvas menu */
.offcanvas {
  max-width: 80% !important;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.dropdown-toggle.no-border {
  border: none;
  background: none;
}

.dropdown-menu {
  background-color: inherit;
}

.navbar-nav .nav-link {
  padding: 0.5rem 1rem;
}

@media (min-width: 992px) {
  .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991px) {
  .desktop-nav {
    display: none;
  }
}


@media (max-width: 768px) {
  .col-orderStatus,
  .col-createdAt,
  .col-totalPrice {
    display: none;
  }
}

/* Menu things */

.dropdown-item.active {
  background-color: var(--bs-secondary) !important;
}